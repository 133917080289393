export type CardProps = {
  color?: CardColors
  className?: string
  as?: keyof Pick<JSX.IntrinsicElements, 'section' | 'div' | 'article'>
  compact?: boolean
}

export enum CardColors {
  PRIMARY = 'primary',
  NEUTRAL = 'neutral',
  YELLOW = 'yellow',
  PINK = 'pink',
  LIGHT_BLUE = 'accent-lightblue',
  LIGHT_BLUE_SUBDUED = 'accent-lightblue-subdued'
}
