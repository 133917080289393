import { createElement, PropsWithChildren } from 'react'
import styles from './Card.module.scss'
import classNames from 'classnames'
import { CardColors, CardProps } from 'components/card/types'

const Card = ({ children, color = CardColors.PRIMARY, className, as = 'div', compact }: PropsWithChildren<CardProps>) => {
  return createElement(as, { className: classNames(styles.card, styles[color], { [styles.compact]: compact }, className) }, children)
}

export default Card
