import { Language } from 'types/language'

export const AVAILABILITY_OVERRIDES = [
  // Offsite override
  {
    end: new Date('2024-01-11T00:00:00.000Z'),
    message: {
      [Language.DUTCH]: 'We zijn vandaag niet telefonisch bereikbaar. Maandagochtend zijn we opnieuw bereikbaar vanaf 9:00.',
      [Language.FRENCH]:
        "Nous ne sommes pas disponibles par téléphone aujourd'hui. Lundi matin, nous serons à nouveau disponibles à partir de 9h00."
    },
    start: new Date('2024-01-13T00:00:00.000Z')
  },
  {
    end: new Date('2024-01-10T16:00:00.000Z'),
    message: {
      [Language.DUTCH]: 'Wij zijn maandagochtend opnieuw bereikbaar vanaf 9:00.',
      [Language.FRENCH]: 'Nous serons à nouveau disponibles lundi matin à partir de 9h00.'
    },
    start: new Date('2024-01-11T00:00:00.000Z')
  }
]
