import { isWeekend, isFriday, isSaturday, getHours } from 'date-fns'
import { useTranslation } from 'next-i18next'
import { AVAILABILITY_OVERRIDES } from 'components/phone-availibility/constants'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'

export const PhoneAvailability = () => {
  // i18n
  const { t } = useTranslation('lookingForHelp')

  // Router
  const { locale } = useRouter()

  /**
   * Returns the text based on wether we're available or not
   *
   * @returns string
   */
  const getAvailability = (): string => {
    const today = new Date()
    const available = !isWeekend(today) && getHours(today) >= 9 && getHours(today) < 18
    const availableButBeforeOpeningHours = !isWeekend(today) && getHours(today) < 9
    const availableTomorrow = !isFriday(today) && !isSaturday(today)

    const phoneAvailabilityOverride = AVAILABILITY_OVERRIDES.find((override) => dayjs(today).isBetween(override.start, override.end))

    if (phoneAvailabilityOverride) return phoneAvailabilityOverride.message[locale]

    if (available) return t('available', 'We zijn vandaag bereikbaar tot 17:00')

    if (availableButBeforeOpeningHours) return t('availableButBeforeOpeningHours', 'We zijn vandaag bereikbaar vanaf 9:00')

    if (availableTomorrow) return t('availableTomorrow', 'We zijn morgen opnieuw bereikbaar vanaf 9:00')

    return t('availableNextMonday', 'We zijn maandag bereikbaar vanaf 9:00')
  }

  return <>{getAvailability()}</>
}
