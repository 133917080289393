import { Product } from 'types/product-data'

export type StartSimulationOrRegistrationParams = {
  ctaTrackingVariant: CtaTrackingVariant
  simulationSalesOffice?: string
  specificProduct?: Product
  startFromUsage?: boolean
}

export enum CtaTrackingVariant {
  DYNAMIC_TARIFF_HERO = 'dynamicPricing',
  DYNAMIC_TARIFF_QUIZ = 'dynamicPricingQuiz',
  HEADER = 'header',
  KNOW_WHERE_YOUR_ELECTRICITY_COMES_FROM = 'knowWhereYourElectricityComesFrom',
  MOVE = 'move',
  PRODUCT_LP = 'productLandingsPage',
  PRODUCER_CARD = 'producerCard',
  START_SIMULATION_FORM = 'startSimulationForm',
  TARIF_PRODUCTS_TABLE = 'tarifProductsTable',
  TARIF_PRODUCT_DRAWER = 'tarifProductDrawer'
}
